import React from "react";
import './i18n';
import {  Trans } from 'react-i18next';
function MessageBox(props) { 

  return (
    <div className={props.messageClass}>
      <Trans i18nKey={'error.' + props.message}>An unexpected error occurred.  Please contact the <a href='https://takeda.service-now.com/AskIT?id=global_contact'>Global Service Desk</a>.</Trans>
    </div>
  );
}

export default MessageBox;