import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security,  LoginCallback} from '@okta/okta-react';
import Home from './Home';

const App  = () => {
  
  const oktaAuth = new OktaAuth({
    issuer: 'https://takeda.okta.com/oauth2/aussx90u3urgHG0rK357',
    clientId: '0oa13tk1g8vjmsZdu358',
    redirectUri: window.location.origin + '/login/callback',
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.href = window.location.origin;  
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/" element={<Home />} />       
        <Route path="/login/callback" element={<LoginCallback />} />        
      </Routes>
      </Security>
  );
};

const AppWithRouterAccess = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouterAccess;