import { useState, useRef, React } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useTranslation, Trans } from "react-i18next";
import Footer from "./Footer";
import Header from "./Header";
import { Buffer } from "buffer";
import i18n from "./i18n";
import MessageBox from "./MessageBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimesCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import Turnstile from "react-turnstile";

const Home = () => {
  const recaptchaRef = useRef();
  const { authState, oktaAuth } = useOktaAuth();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("alert alert-success");
  const [userid, setUserid] = useState("");
  const [email, setEmail] = useState("");
  const [captchaCode, setCaptchaCode] = useState("");
  const [showFactor, setShowFactor] = useState(false);
  const [showInitial, setShowInitial] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showTips, setShowTips] = useState(false);
  const [factorCode, setFactorCode] = useState("");
  const [factor, setFactor] = useState({
    success: false,
    message: "",
    factorDescription: "",
    token: "",
  });
  const [validateFactorResponse, setValidateFactorResponse] = useState({
    success: "",
    message: "",
    token: "",
  });
  //const [passwordResponse, setPasswordResponse] = useState({ success: "", message: "" });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);

  const buttonStyle = {
    marginTop: "10px",
  };
  //const { showInitial } = useState(true);
  const { t } = useTranslation();

  const handleLogin = async () => {
    await oktaAuth.signInWithRedirect({
      loginHint: email,
    });
  };
  const handleLogout = async () => {
    await oktaAuth.signOut();
  };
  function handleUserChange(e) {
    setUserid(e.target.value);
  }
  function handleEmailChange(e) {
    setEmail(e.target.value);
  }
  function handleReCAPTCHAChange(token) {
    setCaptchaCode(token);
  }
  function handleCodeChange(e) {
    setFactorCode(e.target.value);
  }
  function goPrevious(e) {
    if (showPassword) {
      setShowFactor(true);
      setShowPassword(false);
      setFactorCode("");
      setPassword("");
      setConfirmPassword("");
      setCaptchaCode("");
      setValidConfirmPassword(false);
      setValidPassword(false);
    } else {
      setShowInitial(true);
      setShowFactor(false);
      setUserid("");
      setEmail("");
      setCaptchaCode("");
    }
  }
  function htmlEncode(str) {
    return String(str).replace(/[^\w. ]/gi, function (c) {
      return "&#" + c.charCodeAt(0) + ";";
    });
  }
  async function lookupFactor(e, processText) {
    const userinfo = { userid: "", email: "", reCAPTCHACode: "" };
    userinfo.userid = userid;
    userinfo.email = email;
    userinfo.reCAPTCHACode = captchaCode;
    const origText = e.target.textContent;
    e.target.textContent = processText;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userinfo),
    };
    fetch(process.env.REACT_APP_API_URL + "lookupfactor", requestOptions)
      .then(async (response) => {
        const resp = await response.json();

        // handle return of JSON vs string
        var data;
        try {
          data = JSON.parse(resp.body);
        } catch {
          data = resp;
        }
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          e.target.textContent = origText;
          return Promise.reject(error);
        }

        if (data.success === true) {
          setFactor({
            success: data.success,
            message: data.message,
            factorDescription: data.factorDescription,
            token: data.token,
          });
          handleLogin();
          //setShowFactor(true);
          //setShowMessage(false);
          //setShowPassword(false);
          //setShowInitial(false);
        } else {
          setShowFactor(false);
          setShowPassword(false);
          setCaptchaCode("");
          setShowMessage(true);
          setMessage(data.message);
          setMessageClass("alert alert-danger");
          recaptchaRef.current?.reset();
        }
        e.target.textContent = origText;
      })
      .catch((error) => {
        setShowMessage(true);
        setMessage(error.toString());
        setCaptchaCode("");
        setMessageClass("alert alert-danger");
        console.log(error);
        e.target.textContent = origText;
        recaptchaRef.current?.reset();
      });
  }
  async function validateFactor(e, processText) {
    const verifyFactorRequest = { code: "", token: "" };
    verifyFactorRequest.code = factorCode;
    verifyFactorRequest.token = factor.token;
    const origText = e.target.textContent;
    e.target.textContent = processText;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(verifyFactorRequest),
    };
    fetch(process.env.REACT_APP_API_URL + "verifyfactor", requestOptions)
      .then(async (response) => {
        const resp = await response.json();
        var data;
        try {
          data = JSON.parse(resp.body);
        } catch {
          data = resp;
        }

        if (!response.ok) {
          const error = (data && data.message) || response.status;
          e.target.textContent = origText;
          return Promise.reject(error);
        }
        if (data.success === true) {
          setShowPassword(true);
          setShowMessage(false);
          setShowFactor(false);
          setValidateFactorResponse({
            success: data.success,
            message: data.message,
            token: data.token,
          });
        } else {
          setShowPassword(false);
          setShowMessage(true);
          setMessage(validateFactorResponse.message);
          setMessageClass("alert alert-danger");
        }
        e.target.textContent = origText;
      })
      .catch((error) => {
        setMessage(error.toString());
        setShowMessage(true);
        setMessageClass("alert alert-danger");
        console.log(error);
        e.target.textContent = origText;
      });
  }
  function resetPassword(e, processText) {
    var passwordInfoRequest = {
      password: "",
      token: "",
      accessToken: "",
    };
    const origText = e.target.textContent;
    e.target.innerHTML = processText;
    setShowMessage(false);
    passwordInfoRequest.password = password;
    passwordInfoRequest.token = validateFactorResponse.token;
    if (authState.isAuthenticated) {
      passwordInfoRequest.accessToken = authState.accessToken.accessToken;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(passwordInfoRequest),
    };
    fetch(process.env.REACT_APP_API_URL + "changepassword", requestOptions)
      .then(async (response) => {
        const resp = await response.json();
        var data;
        try {
          data = JSON.parse(resp.body);
        } catch {
          data = resp;
        }
        if (!response.ok) {
          console.log(response);
          const error = (data && data.message) || response.status;
          e.target.innerHTML = htmlEncode(origText);
          return Promise.reject(error);
        }

        if (data.success === true) {
          setMessage(data.message);
          setShowMessage(true);
          setMessageClass("alert alert-success");
          setShowPassword(false);
          setShowTips(true);
        } else {
          setShowPassword(true);
          setShowMessage(true);
          setMessage(data.message);
          setMessageClass("alert alert-danger");
        }
      })
      .catch((error) => {
        setMessage(error.toString());
        setShowMessage(true);
        setMessageClass("alert alert-danger");
        console.log(error);
      });
    e.target.textContent = htmlEncode(origText);
  }
  function validatePassword(e) {
    const changedValue = e.target.value;
    setPassword(changedValue);
    if (changedValue.length > 14) {
      setValidPassword(true);
    } else {
      if (validPassword) {
        setValidPassword(false);
      }
    }
    if (
      safeEquals(changedValue, confirmPassword) &&
      confirmPassword.length > 0
    ) {
      setValidConfirmPassword(true);
    } else {
      if (validConfirmPassword) {
        setValidConfirmPassword(false);
      }
    }
  }
  function validateConfirmPassword(e) {
    const changedValue = e.target.value;
    setConfirmPassword(changedValue);
    if (safeEquals(changedValue, password)) {
      setValidConfirmPassword(true);
    } else {
      if (validConfirmPassword) {
        setValidConfirmPassword(false);
      }
    }
  }
  function safeEquals(from, to) {
    var a = Buffer.from(from, "utf8");
    var b = Buffer.from(to, "utf8");

    if (a.length !== b.length) {
      return false;
    }

    var result = 0;
    for (var i = 0; i < a.length; i++) {
      result |= a[i] ^ b[i];
    }
    return result === 0;
  }

  if (!authState) {
    return <div>Loading...</div>;
  }
  return (
    <div className="container">
      <Header />
      {showMessage && (
        <MessageBox message={message} messageClass={messageClass} />
      )}
      {showInitial && !authState.isAuthenticated && (
        <div>
          <div id="nopasswordless">
            <div className="col-md-5">
              <label htmlFor="email">{t("email")}</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("email")}
                  onChange={handleEmailChange}
                />
              </div>
              <label htmlFor="UserName">{t("userid")}</label>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("userid")}
                  onChange={handleUserChange}
                />
              </div>
              <Turnstile
                sitekey="0x4AAAAAAADHpeS_DroOlf7G"
                language={i18n.language}
                onVerify={handleReCAPTCHAChange}
                refs={recaptchaRef}
              />
              <div className="input-group mb-3 mt-3">
                <button
                  className="btn btn-primary"
                  disabled={!email || !userid || !captchaCode}
                  type="button"
                  onClick={(e) => lookupFactor(e, t("button.processing"))}
                >
                  {t("button.next")}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showFactor && !authState.isAuthenticated && (
        <div className="col-md-7">
          <label htmlFor="Code">
            {t("description.prefix")}
            {factor.factorDescription}
            {t("description.suffix")}
          </label>
          <div className="input-group mr-30">
            <input
              type="text"
              className="form-control"
              placeholder={factor.factorDescription + " Code"}
              onChange={handleCodeChange}
            />
          </div>
          <div className="mb-3 email">
            <small>{email}</small>
          </div>

          <div className="input-group mb-3">
            <button
              className="btn btn-primary"
              type="button"
              onClick={goPrevious}
            >
              {t("button.previous")}
            </button>
            &nbsp;&nbsp;
            <button
              className="btn btn-primary"
              disabled={!factorCode}
              type="button"
              onClick={(e) => validateFactor(e, t("button.processing"))}
            >
              {t("button.next")}
            </button>
          </div>
        </div>
      )}
      {(showPassword || authState.isAuthenticated) && !showTips && (
        <div className="col-md-5">
          <div className="mb-4">
            <small>
              <Trans i18nKey="unlock">
                Unlock message here.
                <a href="https://takeda.service-now.com/AskIT?id=global_contact">
                  Global Service Desk
                </a>
              </Trans>
            </small>
          </div>
          {authState.isAuthenticated && (
            <div className="mb-4">
              <small>{authState.idToken.claims.email}</small>&nbsp;&nbsp;
              <button
                className="btn btn-outline-danger btn-sm"
                onClick={handleLogout}
              >
                {t("button.signOut")}
              </button>
            </div>
          )}
          <label htmlFor="password">{t("newPassword")}</label>
          <div className="input-group mb-3">
            <input
              type="password"
              className="form-control"
              id="password"
              name="password"
              value={password}
              onChange={validatePassword}
              required
            />
          </div>
          <label htmlFor="confirmPassword">{t("confirmPassword")}</label>
          <div className="input-group mb-3">
            <input
              type="password"
              className="form-control"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={validateConfirmPassword}
              required
            />
          </div>
          <div>
            {!validPassword && (
              <FontAwesomeIcon icon={faTimesCircle} color="red" />
            )}
            {validPassword && (
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
            )}{" "}
            {t("passwordRequirement1")}
          </div>
          <div>
            {!validConfirmPassword && (
              <FontAwesomeIcon icon={faTimesCircle} color="red" />
            )}
            {validConfirmPassword && (
              <FontAwesomeIcon icon={faCheckCircle} color="green" />
            )}{" "}
            {t("passwordRequirement2")}
          </div>
          <div>{t("passwordRequirement3")}</div>
          <div>{t("passwordRequirement4")}</div>
          <div>{t("passwordRequirement5")}</div>
          <div className="input-group mb-3" style={buttonStyle}>
            {!authState.isAuthenticated && (
              <div>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={goPrevious}
                >
                  {t("button.previous")}
                </button>
                &nbsp;&nbsp;
              </div>
            )}
            <button
              className="btn btn-primary"
              disabled={!validPassword || !validConfirmPassword}
              type="button"
              onClick={(e) => resetPassword(e, t("button.processing"))}
            >
              {t("button.finish")}
            </button>
          </div>
        </div>
      )}
      {showTips && (
        <div className="container tips">
          <div className="row row-cols-2">
            <div className="col colPRS">
              <strong>{t("tips.row1.col1")}</strong>
            </div>
            <div className="col colPRS">
              <strong>{t("tips.row1.col2")}</strong>
            </div>
            <div className="col colPRS">{t("tips.row2.col1")}</div>
            <div className="col colPRS">{t("tips.row2.col2")}</div>
            <div className="col colPRS">{t("tips.row3.col1")}</div>
            <div className="col colPRS">{t("tips.row3.col2")}</div>
            <div className="col colPRS">{t("tips.row4.col1")}</div>
            <div className="col colPRS">{t("tips.row4.col2")}</div>
            <div className="col colPRS">{t("tips.row5.col1")}</div>
            <div className="col colPRS">{t("tips.row5.col2")}</div>
            <div className="col colPRS">{t("tips.row6.col1")}</div>
            <div className="col colPRS">{t("tips.row6.col2")}</div>
            <div className="col colPRS">{t("tips.row7.col1")}</div>
            <div className="col colPRS">{t("tips.row7.col2")}</div>
          </div>
        </div>
      )}    
      <Footer />
    </div>
  );
};

export default Home;
