import React from "react";
import './i18n';
import {  Trans } from 'react-i18next';
function Footer() {    
  return (
    <p style={{'fontSize': '12px', 'textAlign':'center'}}>
      <Trans i18nKey="footer.text"><strong>Need Help?</strong><a href='https://takeda.service-now.com/AskIT?id=global_contact'>Global Service Desk</a>.</Trans>
     </p>
  );
}

export default Footer;