import React from "react";
import i18n from './i18n';

function Header() {    
  return (
    <div>
        <div id="top-bar" className="clearfix">
            <div id="blogo">
                <a href="/">
                    <img src="/images/Takeda2.png" alt="Takeda" />
                </a>
            </div>
            <div id="bphrase">Password Reset Services (PRS)</div>
            
        </div>
        <div id="bphrase" style={{"marginTop": "-5px"}}>
            <img src='/images/jp.png' alt='JP' onClick={() => i18n.changeLanguage('ja')} style={{"pointerEvents": "all"}} />&nbsp;
            <img src='/images/en.png' alt='EN' onClick={() => i18n.changeLanguage('en')} style={{"pointerEvents": "all"}} />&nbsp;
            <img src='/images/de.png' alt='DE' onClick={() => i18n.changeLanguage('de')} style={{"pointerEvents": "all"}} />&nbsp;
            <img src='/images/fr.png' alt='FR' onClick={() => i18n.changeLanguage('fr')} style={{"pointerEvents": "all"}} />&nbsp;
            <img src='/images/es.png' alt='ES' onClick={() => i18n.changeLanguage('es')} style={{"pointerEvents": "all"}} />&nbsp;
            <img src='/images/ru.png' alt='RU' onClick={() => i18n.changeLanguage('ru')} style={{"pointerEvents": "all"}} />&nbsp;
            <img src='/images/cn.png' alt='CN' onClick={() => i18n.changeLanguage('zh')} style={{"pointerEvents": "all"}} />&nbsp;
            <img src='/images/it.png' alt='IT' onClick={() => i18n.changeLanguage('it')} style={{"pointerEvents": "all"}} />&nbsp;
        </div>
    <hr />
    </div>
  );
}

export default Header;